import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { successHandler, errorHandler } from '../handlers'

const URL = `${BASEURL}/agenda`

export default {
  agenda() {
    return {
      create: async ({ title, description }) => {
        const endpoint = `${URL}`
        try {
          const res = await axios.post(
            endpoint,
            { title, description },
            {
              headers: {
                ...Store.getters.getRequestHeaders.headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAll: async () => {
        const endpoint = `${URL}?populate=user&limit=0`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getOne: async ({ agendaId }) => {
        const endpoint = `${URL}/${agendaId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      update: async ({ agendaId }, { status }) => {
        const endpoint = `${URL}/${agendaId}`
        try {
          const res = await axios.put(
            endpoint,
            { status },
            {
              headers: {
                ...Store.getters.getRequestHeaders.headers
              }
            }
          )
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      delete: async ({ agendaId }) => {
        const endpoint = `${URL}/${agendaId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
