<template>
  <div>
    <!-- TODO: Don't show marked agenda -->
    <t-modal v-model="agendaDialog">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Add a new agenda</h4>
        <div class="mt-5">
          <div class="grid grid-cols-1">
            <div class="form-control">
              <label class="text-xs font-semibold text-gray-800 mb-2 block"
                >Title</label
              >
              <t-input
                v-model="agendaData.title"
                class="focus:outline-none"
                type="text"
              ></t-input>
            </div>
            <div class="form-control mt-3">
              <label class="text-xs font-semibold text-gray-800 mb-2 block"
                >Description</label
              >
              <t-textarea
                v-model="agendaData.description"
                class="focus:outline-none"
                type="text"
              ></t-textarea>
            </div>
          </div>
          <div class="flex mt-5 items-center justify-end">
            <t-button
              @click="addAgenda"
              class="flex items-center justify-center"
            >
              <span v-if="!addingAgenda">Save</span> <spinner v-else></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </t-modal>
    <t-modal v-model="reminderDialog">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Add a new reminder</h4>
        <div class="mt-5">
          <div class="grid grid-cols-1 gap-8 md:grid-cols-2">
            <div class="form-control">
              <label class="text-xs font-semibold text-gray-800 mb-2 block"
                >Title</label
              >
              <t-input
                v-model="reminderData.title"
                class="focus:outline-none"
                type="text"
              ></t-input>
            </div>
            <div class="form-control">
              <label class="text-xs font-semibold text-gray-800 mb-2 block"
                >Date</label
              >
              <t-input
                v-model="reminderData.date"
                class="focus:outline-none"
                type="date"
              ></t-input>
            </div>
            <div class="form-control mt-3 md:col-span-2">
              <label class="text-xs font-semibold text-gray-800 mb-2 block"
                >Description</label
              >
              <t-textarea
                v-model="reminderData.description"
                class="focus:outline-none"
                type="text"
              ></t-textarea>
            </div>
          </div>
          <div class="flex mt-5 items-center justify-end">
            <t-button
              @click="addReminder"
              class="flex items-center justify-center"
            >
              <span v-if="!addingReminder">Save</span>
              <spinner v-else></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </t-modal>
    <page-title>Your Workspace</page-title>
    <page-subtitle>Welcome, {{ user.firstName }}.</page-subtitle>
    <div class="mt-5">
      <section-title>Overview</section-title>
      <div class="overview grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-10">
        <div
          id="agenda"
          class="border-t-8 bg-white border-primary w-full shadow-md"
        >
          <div class="flex items-center justify-between px-4">
            <p class="text-xs font-semibold py-2 px-2">Today's Agenda</p>
            <span
              @click="agendaDialog = true"
              class="text-primary text-xs cursor-pointer font-semibold"
              >Add</span
            >
          </div>
          <div
            v-if="agendaList.length === 0"
            class="bg-white flex items-center justify-center p-5 h-52"
          >
            <span class="text-sm"
              >You don't have any event schedule for today.
              <span
                @click="agendaDialog = true"
                class="text-primary cursor-pointer font-semibold"
                >Add</span
              >
            </span>
          </div>
          <div
            v-else-if="markingAgendaDone"
            class="flex items-center justify-center"
          >
            <spinner color="text-primary"></spinner>
          </div>
          <div
            v-else
            class="text-xs flex overflow-y-scroll flex-col h-32 space-y-3 px-4 mt-5"
          >
            <div
              v-for="list in agendaList"
              :key="list._id"
              :class="
                `${list.status === 'done' ? 'line-through text-gray-300' : ''}`
              "
            >
              <div class="flex items-center justify-between">
                <span
                  :class="
                    `font-semibold ${
                      list.status === 'done' ? 'text-gray-300' : ''
                    }`
                  "
                  >{{ list.title }}</span
                >
                <span
                  @click="
                    list.status !== 'done' ? markAgendaDone(list) : () => {}
                  "
                  class="font-semibold cursor-pointer"
                  >mark done</span
                >
              </div>
              <div class="text-gray-500">
                <span>{{ list.description }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div id="activity" class="border-t-8 bg-white border-primary shadow-md">
          <div class="flex space-x-2 items-center justify-between">
            <p class="text-xs font-semibold py-2 px-2">Activity Report</p>
            <p
              class="text-xs font-semibold py-2 px-2 cursor-pointer text-primary"
            >
              View full activity report
            </p>
          </div>
          <div class="bg-white flex items-center justify-center p-5 h-32">
            <span class="text-sm"
              >You don't have nay event schedule for today.
              <span class="text-primary font-semibold cursor-pointer">Add</span>
            </span>
          </div>
        </div> -->
        <!-- <div id="tasks" class="border-t-8 bg-white border-primary shadow-md">
          <div class="flex space-x-2 items-center justify-between">
            <p class="text-xs cursor-pointer font-semibold py-2 px-2">
              Due Tasks
            </p>
            <router-link
              :to="{ name: 'Tasks' }"
              class="text-xs font-semibold py-2 px-2 cursor-pointer text-primary"
            >
              Create Tasks
            </router-link>
          </div>
          <div class="bg-white flex items-center justify-center p-5 h-32">
            <span class="text-sm"
              >You don't have any task to be due
              <router-link
                :to="{ name: 'Tasks' }"
                class="text-primary cursor-pointer font-semibold"
                >Add</router-link
              >
            </span>
          </div>
        </div> -->
        <div
          id="reminders"
          class="border-t-8 bg-white border-primary shadow-md"
        >
          <div class="flex space-x-2 items-center justify-between">
            <div class="flex items-center justify-between w-full px-4">
              <p class="text-xs font-semibold py-2 px-2">Reminders</p>
              <span
                @click="reminderDialog = true"
                class="text-primary font-semibold text-xs cursor-pointer"
                >Add</span
              >
            </div>
          </div>
          <div
            v-if="reminderList.length === 0"
            class="bg-white flex items-center justify-center p-5 h-52"
          >
            <span class="text-sm"
              >You don't have any reminder coming up.
              <span
                @click="reminderDialog = true"
                class="text-primary cursor-pointer font-semibold"
                >Create</span
              >
            </span>
          </div>
          <div
            v-else
            class="text-xs flex overflow-y-scroll flex-col h-32 space-y-3 px-4 mt-5"
          >
            <div
              v-for="list in reminderList"
              :key="list._id"
              :class="
                `${list.status === 'done' ? 'line-through text-gray-300' : ''}`
              "
            >
              <div class="flex items-center justify-between">
                <span
                  :class="
                    `font-semibold ${
                      list.status === 'done' ? 'text-gray-300' : ''
                    }`
                  "
                  >{{ list.title }}</span
                >
                <span>{{ new Date(list.date).toDateString() }}</span>
              </div>
              <div class="text-gray-500">
                <span>{{ list.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionTitle from '../../components/Typography/SectionTitle.vue'
import Spinner from '../../components/Utils/Spinner.vue'
import agendaApi from '../../api/agenda'
import reminderApi from '../../api/reminder'

export default {
  components: { PageTitle, PageSubtitle, SectionTitle, Spinner },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  data: () => ({
    agendaDialog: false,
    addingAgenda: false,
    agendaData: {
      title: '',
      description: ''
    },
    markingAgendaDone: false,
    agendaList: [],
    reminderDialog: false,
    addingReminder: false,
    reminderData: {
      title: '',
      date: '',
      description: ''
    },
    markingReminderDone: false,
    reminderList: []
  }),
  created() {
    this.getAgendas()
    this.getReminders()
  },
  methods: {
    async addAgenda() {
      this.addingAgenda = true
      const res = await agendaApi.agenda().create({
        title: this.agendaData.title,
        description: this.agendaData.description
      })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.addingAgenda = false
        return
      }

      this.getAgendas()

      this.addingAgenda = false
      this.agendaDialog = false
      this.agendaData.title = ''
      this.agendaData.description = ''

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
    },
    async getAgendas() {
      const res = await agendaApi.agenda().getAll()
      this.agendaList = res.data.data.sort((a, b) => {
        const x = a.status === 'done' ? 1 : 0
        const y = b.status === 'done' ? 1 : 0
        return x - y
      })
    },
    async getReminders() {
      const res = await reminderApi.reminders().getAll()
      this.reminderList = res.data.data.sort((a, b) => {
        const x = a.status === 'done' ? 1 : 0
        const y = b.status === 'done' ? 1 : 0
        return x - y
      })
    },
    async markAgendaDone(agenda) {
      this.markingAgendaDone = true
      const res = await agendaApi
        .agenda()
        .update({ agendaId: agenda._id }, { status: 'done' })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.markingAgendaDone = false
        return
      }
      this.getAgendas()
      this.markingAgendaDone = false
    },

    async addReminder() {
      this.addingReminder = true
      const res = await reminderApi.reminders().create({
        title: this.reminderData.title,
        description: this.reminderData.description,
        date: this.reminderData.date
      })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.addingAgenda = false
        return
      }

      this.getReminders()

      this.addingReminder = false
      this.reminderDialog = false
      this.reminderData.title = ''
      this.reminderData.description = ''
      this.reminderData.date = ''

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
    }
  }
}
</script>

<style></style>
