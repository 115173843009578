<template>
  <div class="mb-4 text-lg font-semibold text-gray-600">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle'
}
</script>

<style></style>
